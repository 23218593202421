.s-container{
    width: 100vw;
    height: 100vh;
    background-color:rgb(29, 49, 82);
    color: white;
}
.body{
    display: flex;
    justify-content: space-between;
}
.s-heading{
    text-align: center;
    font-size: 40px;
    font-weight: 700;
    margin-top: 10vh;
    
}
.s-left{
    margin-left: 200px;
    width: 350px;
   
}
.s-left h2{
    text-align: center;
    margin-top: 30px;
color:rgb(244, 221, 15) ;
}
.skills-name{
    color: aqua;
}
.technology{
    font-size: 20px;
    font-weight: 600;
    width: 350px;
    display: flex;
    justify-content: space-between;
}
.html{
    margin-top: 10px;
}
.outer-div{
    margin-top: 5px;
 width: 350px;
 background-color: white;
 height: 5px;
}
.inner-div{
    width: 90%;
    background-color: aqua;
    height: 5px;
}
.icons{
    font-size: 25px;
    color: red;
}
.css-percentage{
  margin-right: 20%;
}
.inner-div-css{
    width: 80%;
    background-color: aqua;
    height: 5px;
}
.css-icon{
    font-size: 29px;
    color: rgb(17, 0, 255);
}
.js-percentage{
    margin-right: 25%;
  }
  .inner-div-js{
      width: 75%;
      background-color: aqua;
      height: 5px;
  }
  .js-icon{
      font-size: 25px;
      color: rgb(102, 255, 0);
  }
  .react-icon{
    font-size: 29px;
    color: rgb(0, 238, 255);
}
.java-percentage{
    margin-right: 25%;
  }
  .inner-div-java{
      width: 75%;
      background-color: aqua;
      height: 5px;
  }
  .java-icon{
      font-size: 25px;
      color: rgb(255, 0, 242);
  }
  .s-right{
    width: 350px;
    margin-right: 250px;
  }
.top-row{
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
}
.bottom-row{
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
}
.circle{
    width: 120px;
}

.s-right h2{
    text-align: center;
    margin-top: 30px;
    color:rgb(244, 221, 15) ;
}
.s-text1{
    text-align: center;
    
    margin-top: 10px;
}
.s-text2{
    
    text-align: center;
    margin-top: 10px;
}
.s-text3{
    text-align: center;
    margin-top: 10px;
}
.s-text4{
    text-align: center;
    margin-top: 10px;
}


/* -------smaller-screens-------- */

@media screen and (max-width:400px){

    .s-container{
        width: 100vw;
        height: 142vh;
        background-color:rgb(29, 49, 82);
        color: white;
    }
    .body{
        display: block;
    }
    .s-heading{
        text-align: center;
        font-size: 40px;
        font-weight: 700;
        margin-top: 7vh;
        
    }
    .s-left{
        margin-left: 20px;
        width: 350px;
    }
    .s-left h2{
        text-align: center;
        margin-top: 30px;
    color:rgb(244, 221, 15) ;
    }
    .s-right{
        width: 350px;
        margin-left: 20px;
      }
}


